import React, { useMemo } from 'react'
import { IonHeader, IonContent, IonToolbar, IonTitle } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { gdprText } from './gdprAndTerms'
import { APP } from 'mimex-libs/config';


const GDPRModal = () => {
  const { t } = useTranslation(["translation"]);
  const customDeployment = useMemo(() => localStorage.getItem("customDeployment") || APP.DEPLOYMENT, [])

  return (
    <>
      <IonHeader translucent data-testid="gdpr-page">
        <IonToolbar>
          <IonTitle>{t("GDPR")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent class="ion-padding full-height">
        {(customDeployment === 'staging') ?
          <>
            <b>Privacy Policy:</b><br />
            Ai sensi degli art. 13 e 14 del Regolamento UE 2016/679 in materia di protezione dei dati personali (“GDPR”) ed in generale in osservanza del principio di trasparenza previsto dal Regolamento medesimo, di seguito si forniscono le informazioni in merito al trattamento dei dati
            personali da parte del Circolo FBK.
            <br />
            <b>Titolare del trattamento.</b><br />
            Il Titolare del trattamento dei dati personali è il Circolo FBK, con sede in Via Sommarive 18, 38123 Trento - mail circolo@fbk.eu - PEC circolofbk@pec.it
            <br />
            <b>Finalità del trattamento. </b><br />
            Il Circolo FBK tratta i dati personali esclusivamente per lo svolgimento della sperimentazione ed in particolare per analisi statistiche in forma aggregata ed anonima;
            <br />
            <b>Natura dei dati personali trattati. </b><br />
            Potranno essere oggetto di trattamento dati di natura comune (dati anagrafici) e i dati relativi agli orari di acquisto e beni acquistati
            <br />
            <b>Base giuridica del trattamento e obbligatorietà del conferimento dei dati.</b>
            La base giuridica del trattamento è rappresentata dalla richiesta di adesione alla sperimentazione (art. 6 comma 1 lett. b GDPR), dal consenso al trattamento (art. 6 comma 1 lett. a - art. 9 comma 2 lett. a GDPR),  dagli obblighi legali a cui è tenuto il Circolo FBK (art. 6 comma 1 lett. c GDPR). Il conferimento dei dati anagrafici e di contatto è necessario in quanto strettamente legato alla gestione della sperimentazione.
            <br />
            <b>Modalità e principi del trattamento. </b><br />
            Il trattamento avverrà nel rispetto del GDPR e del D.Lgs. n. 196/03 (“Codice in materia di protezione dei dati personali”), nonché dei principi di liceità, correttezza e trasparenza, adeguatezza e pertinenza, con modalità cartacee ed informatiche, ad opera di persone autorizzate e con l'adozione di misure adeguate di protezione, in modo da garantire la sicurezza e la riservatezza dei dati e in contesti che non pregiudicano la dignità personale ed il decoro dell'interessato.Non verrà svolto alcun processo decisionale automatizzato.
            <br />
            <b>Comunicazione dei dati e luogo di trattamento dei dati. </b>
            I dati personali raccolti ed elaborati non potranno essere comunicati a nessun soggetto terzo, ma serviranno esclusivamente a testare il funzionamento del prototipo
            <br />
            <b>Periodo di conservazione dei dati. </b><br />
            I dati saranno utilizzati dal Circolo FBK fino alla fine della sperimentazione. Dopo tale data, saranno conservati per finalità di archivio, con esclusione di comunicazioni a terzi e diffusione in ogni caso applicando i principi di proporzionalità e minimizzazione.
            <br />
            <b>Diritti dell'interessato. </b><br />
            All'interessato sono garantiti tutti i diritti specificati al Capo III del GDPR, tra cui il diritto all'accesso, rettifica e cancellazione dei dati, il diritto di limitazione e opposizione al trattamento, il diritto di revocare il consenso al trattamento (senza pregiudizio
            per la liceità del trattamento basata sul consenso acquisito prima della revoca), nonché il diritto di proporre reclamo al Garante per la Protezione dei dati personali qualora ritenga che il trattamento che lo riguarda violi il GDPR o la normativa italiana.
            I suddetti diritti possono essere esercitati mediante comunicazione scritta da inviare a mezzo posta elettronica, PEC, o a mezzo di Posta Raccomandata presso la sede del Circolo FBK
          </>

          : (
            <>
              {t(gdprText)}
            </>
          )}
      </IonContent>
    </>
  )
}

export default GDPRModal