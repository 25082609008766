import {
    IonApp, IonRouterOutlet
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { getPaymentCardSecretHandler } from "mimex-libs/api/endpoints";
import { getCustomerAppConfig } from "mimex-libs/config";
import { BASE_URL, ERROR, PATH_BILLING_ADDRESS, PATH_HELP, PATH_HOME, PATH_LOGIN, PATH_LOGOUT, PATH_ONBOARDING, PATH_PASSWORD_RESET, PATH_PAYMENT, PATH_PAYMENT_METHODS, PATH_RECEIPTS, PATH_SETTINGS, PATH_STORES, PATH_USER_ACCOUNT, PATH_VERIFY_EMAIL, PATH_VIRTUAL_CART } from "mimex-libs/helpers/constants";
import { deleteUserToken, emitter, getOnBoardingSession, getTokenPayload, isLoggedIn, memoryStore, setUserToken, UserStoreChanged, UserStoreOperation, UserStoreType } from "mimex-libs/helpers/userStore";
import { createLogger } from "mimex-libs/logger";
import React, { useCallback, useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { VersionChecker } from "./components/VersionChecker";
import { ConfigLoader } from './ConfigLoader';
import useToast from './CustomHooks/useToast';
import { Account } from "./pages/account/Account";
import { EmailValidation } from './pages/account/EmailValidation';
import PasswordReset from "./pages/account/PasswordReset";
import BillingAddressUpdate from "./pages/BillingAddressUpdate";
import HelpAndSupport from "./pages/help/HelpAndSupport";
import { OnBoarding } from "./pages/onboarding/OnBoarding";
import PageNotFound from "./pages/PageNotFound";
import ManagePaymentMethod from "./pages/payment/ManagePaymentMethod";
import PaymentPage from './pages/PaymentPage';
import { Dashboard } from "./pages/qrcode/Dashboard";
import ReceiptDetails from "./pages/ReceiptDetails";
import Receipts from "./pages/Receipts";
import Settings from "./pages/Settings";
import StoresMap from "./pages/StoresMap";
import UserAccount from './pages/UserAccount';
import VirtualCart from "./pages/VirtualCart";
/* Theme variables */
import "./theme/variables.css";

// wait for configloader
const stripePromise = new Promise<Stripe | null>((resolve, reject) => {
    emitter.once(UserStoreType.configLoader, (ev: UserStoreChanged) => {
        if (ev.op !== UserStoreOperation.UPDATED) {
            return
        }
        const config = getCustomerAppConfig()
        let apiKey = config.STRIPE_API_KEY || process.env['REACT_APP_STRIPE_API_KEY'] || ''
        loadStripe(apiKey).then(resolve).catch(reject)
    })
})

const logger = createLogger({ component: 'App' })

const App: React.FC = () => {

    const history = useHistory()
    const [renderToast] = useToast()

    logger.debug(`current path: ${history.location?.pathname}`)

    const goTo = useCallback((path: string) => {

        // move away from login if loggedin
        if (path === PATH_LOGIN && isLoggedIn()) path = PATH_HOME

        // do not redirect if same page
        if (path === history.location?.pathname) return

        logger.debug(`Go to ${path}`, { section: 'routing' })
        history.push(path)

    }, [history])

    const hasPaymentFlag = useCallback(() => {
        return memoryStore.get(UserStoreType.paymentMethod) === true
    }, [])

    const checkPayment = useCallback(() => {

        if (hasPaymentFlag()) {
            // emit event
            memoryStore.set(UserStoreType.paymentMethod, true)
            logger.debug("Payment is set", { section: 'routing' })
            return
        }

        getPaymentCardSecretHandler()
            .then((paymentMethods1) => {
                memoryStore.set(UserStoreType.paymentMethod, (paymentMethods1 && paymentMethods1.length > 0))
            })
            .catch((e) => {
                logger.error(`Failed to load payments ${e.stack}`, { section: 'routing' })
                renderToast(ERROR, e)
                if (e.response && e.response.status === 401) {
                    goTo(PATH_LOGOUT)
                    return
                }
                memoryStore.set(UserStoreType.paymentMethod, false)
            })

    }, [hasPaymentFlag, renderToast])

    const onCardSaved = () => {
        logger.debug("onCardSaved: go to payment page", { section: 'routing' })
        goTo(PATH_HOME)
    }

    const onLoginSuccess = (jwt: string) => {
        logger.debug("onLoginSuccess: set token", { section: 'routing' })
        setUserToken(jwt)
    }

    const onRegistrationSuccess = () => {
        logger.debug("onRegistrationSuccess: go to email validation", { section: 'routing' })
        goTo(PATH_VERIFY_EMAIL)
    }

    useEffect(() => {

        const onPaymentChange = (ev: UserStoreChanged) => {

            let path = history.location?.pathname

            if (!hasPaymentFlag()) {
                logger.debug("Payment not configured, go to payment methods page", { section: 'routing' })
                path = PATH_PAYMENT_METHODS
            } else {
                logger.debug(`Payment configured, continue to ${path}`, { section: 'routing' })
            }

            goTo(path)
        }

        emitter.on(UserStoreType.paymentMethod, onPaymentChange)

        const onTokenChange = (ev: UserStoreChanged) => {
            if (ev.op === UserStoreOperation.UPDATED) {
                // check if email is validated
                logger.debug('token updated', { section: 'routing' })
                if (!isLoggedIn()) {
                    // go to validation page
                    logger.debug('store: token not valid, resetting', { section: 'routing' })
                    goTo(PATH_LOGOUT)
                    return
                }

                // check if payment info is avail
                checkPayment()
            }
            if (ev.op === UserStoreOperation.REMOVED) {
                logger.debug('token removed', { section: 'routing' })
                logger.debug('store: token removed, go to login', { section: 'routing' })
                goTo(PATH_LOGIN)
            }
        }
        emitter.on(UserStoreType.userToken, onTokenChange)

        return () => {
            emitter.off(UserStoreType.paymentMethod, onPaymentChange)
            emitter.off(UserStoreType.userToken, onTokenChange)
        }

    }, [checkPayment, goTo, hasPaymentFlag, history.location?.pathname])

    useEffect(() => {

        // SKIP ONBOARDING PAGE
        // const showOnboarding = !getOnBoardingSession()
        // if (showOnboarding) {
        //     logger.debug('Show Onboarding', { section: 'routing' })
        //     goTo(PATH_ONBOARDING)
        //     return
        // }

        // allow to visit password reset
        const unauthenticatedPath = [
            PATH_PASSWORD_RESET
        ]
        const currentLocation = document.location.pathname.replace(BASE_URL, '')
        if (unauthenticatedPath.includes(currentLocation)) {
            return
        }

        // 1. has token?
        const tokenPayload = getTokenPayload()
        if (!tokenPayload) {
            logger.debug('Token not available', { section: 'routing' })
            goTo(PATH_LOGIN)
            return
        }
        // 2. token is valid?
        if (!isLoggedIn()) {
            // redirected by store event handler
            logger.debug('Token expired', { section: 'routing' })
            deleteUserToken()
            return
        }
        // 3. has validated email?
        if (!tokenPayload.email_verified) {
            // redirected by store event handler
            logger.debug('Email not verified', { section: 'routing' })
            goTo(PATH_VERIFY_EMAIL)
            return
        }
        // 4. has payment info?
        checkPayment()

    }, [checkPayment, goTo, history])

    // on location change, check payment has been added
    useEffect(() => history.listen((nextProp) => {
        const allowedPath = [
            PATH_PAYMENT_METHODS,
            PATH_USER_ACCOUNT,
            PATH_LOGOUT,
            PATH_SETTINGS,
            PATH_HELP,
        ]
        if (isLoggedIn() && allowedPath.indexOf(nextProp.pathname) === -1 && !hasPaymentFlag()) {
            logger.debug('Payment check flag missing, force check', { section: 'routing' })
            checkPayment()
        }
    }), [checkPayment, goTo, hasPaymentFlag, history])

    return (
        <ConfigLoader>
            <Elements stripe={stripePromise}>
                <VersionChecker />
                <IonApp data-testid="app-page">
                    <IonReactRouter history={history}>
                        <IonRouterOutlet>
                            <Switch>
                                <Route
                                    data-testid="account-page"
                                    exact
                                    path={PATH_LOGIN}
                                    children={<Account onRegistrationSuccess={onRegistrationSuccess} onLoginSuccess={onLoginSuccess} />}
                                />
                                <Route
                                    exact
                                    path={PATH_PASSWORD_RESET}
                                    children={<PasswordReset />}
                                />
                                <Route
                                    exact
                                    path={PATH_ONBOARDING}
                                    children={<OnBoarding />}
                                />
                                <Route
                                    exact
                                    path={PATH_VERIFY_EMAIL}
                                    children={<EmailValidation />}
                                />
                                <Route exact path={PATH_HOME} component={Dashboard} />
                                <Route
                                    exact
                                    path={PATH_PAYMENT_METHODS}
                                    children={<ManagePaymentMethod onCardSaved={onCardSaved} />}
                                />
                                <Route
                                    exact
                                    path={PATH_BILLING_ADDRESS}
                                    children={<BillingAddressUpdate />}
                                />
                                <Route
                                    exact
                                    path={PATH_PAYMENT}
                                    children={<PaymentPage />}
                                />
                                <Route path={PATH_RECEIPTS} render={({ match: { url } }) => (
                                    <>
                                        <Route path={`${url}/`} component={Receipts} exact />
                                        <Route path={`${url}/:sessionId`} component={ReceiptDetails} />
                                    </>
                                )}>
                                </Route>
                                <Route
                                    exact
                                    path={PATH_STORES}
                                    children={<StoresMap />}
                                />
                                <Route
                                    exact
                                    path={PATH_USER_ACCOUNT}
                                    children={<UserAccount />}
                                />
                                <Route
                                    exact
                                    path={PATH_VIRTUAL_CART}
                                    children={<VirtualCart />}
                                />
                                <Route
                                    exact
                                    path={PATH_LOGOUT}
                                    children={() => {
                                        deleteUserToken()
                                        return <></>
                                    }}
                                />
                                <Route
                                    exact
                                    path={PATH_SETTINGS}
                                    children={<Settings />}
                                />
                                <Route
                                    exact
                                    path={PATH_HELP}
                                    children={<HelpAndSupport />}
                                />
                                <Route data-testid="invalid-path" path="*" children={<PageNotFound />} />
                            </Switch>
                        </IonRouterOutlet>
                    </IonReactRouter>
                </IonApp>
            </Elements >
        </ConfigLoader>
    );
}

export default App;
